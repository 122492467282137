
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { SaveCrmCustomerRequest } from '@/models/Interfaces';
import { CrmCustomerSaveChanges } from '@/models/Enums';

export default defineComponent({
    props: {
        crmCustomerId: {
            type: String,
            required: true
        }
    },
    components: {
        OverlayModal
    },
    name: 'CrmCustomerRemarksPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const remarks = ref('');
        async function open(remarksParam: string) {
            remarks.value = remarksParam;
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        async function saveCrmCustomerRemarks() {
            swal.showLoading();
            const req = new SaveCrmCustomerRequest();
            req.id = props.crmCustomerId;
            req.remarks = remarks.value;
            req.changes = CrmCustomerSaveChanges.Remarks;
            const response = await api.saveCrmCustomer(req);
            if (response.errorMessage) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            emit('updateRemarks', remarks.value);
            close();
            swal.close();
        }
        return {
            isOpen,
            open,
            close,
            remarks,
            saveCrmCustomerRemarks
        };
    }
});
