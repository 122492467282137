
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import Multiselect from '@vueform/multiselect';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import { QuickReplyMessageEditModel } from '@/models/CrmCustomers';
import { generalStore } from '@/store';
import { IdName } from '@/models/Interfaces';
import _ from 'lodash';

export default defineComponent({
    components: {
        OverlayModal,
        Multiselect
    },
    name: 'QuickReplyEditorPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);
        const quickReplyMessage = ref(new QuickReplyMessageEditModel());
        const originQuickReplyMessage = ref(new QuickReplyMessageEditModel());
        const leadTypes = ref<IdName[]>([]);
        async function open(quickReplyMessageParam: QuickReplyMessageEditModel | null = null) {
            swal.showLoading();
            const element = document.getElementById('quick-reply-editor-form');
            if (element) {
                element.classList.remove('was-validated');
            }
            quickReplyMessage.value = new QuickReplyMessageEditModel();
            if (quickReplyMessageParam) {
                quickReplyMessage.value = _.clone(quickReplyMessageParam);
                originQuickReplyMessage.value = _.clone(quickReplyMessageParam);
            }
            leadTypes.value = await generalStore.dispatch('loadLeadTypes');
            isOpen.value = true;
            swal.close();
        }
        function close() {
            isOpen.value = false;
        }
        const submitForm = async (event: any) => {
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                return;
            }
            if (_.isEqual(originQuickReplyMessage, quickReplyMessage)) {
                close();
                return;
            }

            swal.showLoading();
            const response = await api.saveQuickReplyMessage(quickReplyMessage.value);
            if (response.validationErrors?.length) {
                const html = response.validationErrors
                    .map(x => {
                        return `<div>${x.key}: ${x.msg}</div>`;
                    })
                    .join(',');
                swal.fire({
                    icon: 'error',
                    html: html
                });
                return;
            }
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage || 'No data'
                });
                return;
            }
            swal.fire({
                position: 'center',
                icon: 'success',
                showConfirmButton: false,
                timer: 1500
            });
            emit('saveQuickReply', response.data);
            close();
        };
        return {
            isOpen,
            open,
            close,
            quickReplyMessage,
            submitForm,
            leadTypes
        };
    }
});
