
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';

export default defineComponent({
    components: {
        OverlayModal
    },
    name: 'CrmCustomerActionsPopup',
    async setup(props, { emit }) {
        const isOpen = ref(false);

        async function open() {
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function selectAction() {
            close();
        }
        function onSelectAction(type: string) {
            emit('onSelectAction', type);
            close();
        }

        return {
            isOpen,
            open,
            close,
            selectAction,
            onSelectAction
        };
    }
});
