
import { defineComponent, ref } from 'vue';
import OverlayModal from '@/components/Calendar/OverlayModal.vue';
import { CrmCustomerReminderEditorModel, CrmCustomerReminderSaveRequest, CrmCustomerReminderDetailsModel } from '@/models/CrmCustomers';
import RadioInput from '@/components/RadioInput.vue';
import TimeInput from '@/components/TimeInput.vue';
import Calendar from 'primevue/calendar';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import moment from 'moment';
import _ from 'lodash';
import { DateConstants } from '@/models/Constants';
import { useI18n } from 'vue-i18n';
import q from 'q';
import { ReminderSaveChanges } from '@/models/Enums';
import { getFlagChanges } from '@/helper';

export default defineComponent({
    props: {
        crmCustomerId: {
            type: String,
            required: true
        },
        customerName: {
            type: String,
            required: true
        }
    },
    components: {
        OverlayModal,
        RadioInput,
        Calendar,
        TimeInput
    },
    name: 'CrmCustomerReminderEditorModal',
    async setup(props: any, { emit }: any) {
        const { t } = useI18n();
        const loading = ref(false);
        const invalidAlertDate = ref(false);
        const reminder = ref(new CrmCustomerReminderEditorModel());
        const originReminder = ref(new CrmCustomerReminderEditorModel());
        const isOpen = ref(false);
        const alertDate = ref<Date | null>(null);
        const dateConstants = DateConstants;

        async function open(reminderParam: CrmCustomerReminderDetailsModel | null = null) {
            invalidAlertDate.value = false;
            const elements = document.querySelectorAll('.was-validated');
            elements.forEach(element => {
                (element as HTMLElement).classList.remove('was-validated');
            });
            if (reminderParam) {
                const dateArr = reminderParam.alertDateTime.split(' ');
                alertDate.value = moment(dateArr[0], DateConstants.DATE_FORMAT_SHOW).toDate();

                reminder.value.id = reminderParam.id;
                reminder.value.name = reminderParam.name;
                reminder.value.active = reminderParam.active;
                reminder.value.onlyForMe = reminderParam.onlyForMe;
                reminder.value.alertDate = dateArr[0];
                reminder.value.alertTime = dateArr[1];
                reminder.value.relativeInterval = reminderParam.relativeInterval;

                originReminder.value = _.clone(reminder.value);
            } else {
                reminder.value = new CrmCustomerReminderEditorModel();
                alertDate.value = null;
            }
            isOpen.value = true;
        }
        function close() {
            isOpen.value = false;
        }
        function save() {
            close();
        }
        function setNotValid(errMsg: string, fieldId: string, fieldErrorId: string) {
            const field = document.getElementById(fieldId) as HTMLInputElement;
            field.classList.add('error-input-style');
            field.setCustomValidity(errMsg);
            const fieldError = document.getElementById(fieldErrorId) as HTMLInputElement;
            fieldError.classList.add('d-block');
            const fieldErrorMsg = document.querySelector(`#${fieldErrorId} .invalid-feedback-msg`) as HTMLInputElement;
            fieldErrorMsg.textContent = errMsg;
        }

        function clearNotValid(fieldId: string, fieldErrorId: string) {
            const field = document.getElementById(fieldId) as HTMLInputElement;
            field.setCustomValidity('');
            field.classList.remove('error-input-style');
            const fieldError = document.getElementById(fieldErrorId) as HTMLInputElement;
            fieldError.classList.remove('d-block');
            const fieldErrorMsg = document.querySelector(`#${fieldErrorId} .invalid-feedback-msg`) as HTMLInputElement;
            if (fieldErrorMsg) {
                fieldErrorMsg.textContent = t('valid.isRequired');
            }
        }

        function setValidator(codes: { key: string; msg: string }[]) {
            const subjectError = codes.find(x => x.key == 'Name');
            if (subjectError) {
                const subjectField = document.getElementById('subjectField') as HTMLInputElement;
                subjectField.setCustomValidity(subjectError.msg);
                const subjectFieldMsg = document.querySelector('#subjectField .subjectFieldError') as HTMLInputElement;
                if (subjectFieldMsg) {
                    subjectFieldMsg.textContent = subjectError.msg;
                }
            }

            const alertTimeError = codes.find(x => x.key == '$.alertTime');
            if (alertTimeError) {
                setNotValid(alertTimeError.msg, 'alertTimeField', 'alertTimeFieldError');
            }

            const alertDateError = codes.find(x => x.key == '$.alertDate');
            if (alertDateError) {
                setNotValid(alertDateError.msg, 'alertDateField', 'alertDateFieldError');
            }
        }
        function clearValidator() {
            // required
            // subjectField validation
            const subjectField = document.getElementById('subjectField') as HTMLInputElement;
            subjectField.setCustomValidity('');
            const subjectFieldMsg = document.querySelector('#subjectField .subjectFieldError') as HTMLInputElement;
            if (subjectFieldMsg) {
                subjectFieldMsg.textContent = t('valid.isRequired');
            }

            // required
            // alertDate validation
            clearNotValid('alertDateField', 'alertDateFieldError');

            // required
            // alertTime validation
            clearNotValid('alertTimeField', 'alertTimeFieldError');
        }

        const submitForm = async (event: any) => {
            clearValidator();
            event.target.classList.add('was-validated');
            if (event.target.checkValidity() === false) {
                event.preventDefault();
                event.stopPropagation();
                swal.fire({
                    icon: 'warning',
                    title: t('alert.mandatoryfields'),
                    confirmButtonText: t('button.close')
                });
                return;
            }
            let hasError = false;
            const alertTimeMoment = moment(reminder.value.alertTime, 'HH:mm', true);
            if (!alertTimeMoment.isValid()) {
                setNotValid(t('valid.isRequired'), 'alertTimeField', 'alertTimeFieldError');
                hasError = true;
            }
            const alertDateMoment = moment(alertDate.value, 'DD/MM/YYYY');
            if (!alertDateMoment.isValid()) {
                setNotValid(t('valid.isRequired'), 'alertDateField', 'alertDateFieldError');
                hasError = true;
            }
            if (hasError) {
                return;
            }
            swal.showLoading();
            const alertDateTimeString = moment(alertDateMoment)
                .set({
                    hour: alertTimeMoment.hour(),
                    minute: alertTimeMoment.minute()
                })
                .format(DateConstants.SERVER_NODATIME_LOCAL_DATE_TIME_FORMAT);
            let changes;
            if (reminder.value.id) {
                changes = getFlagChanges(originReminder.value, reminder.value, ReminderSaveChanges, key => {
                    if (key === 'alertDate' || key === 'alertTime') return 'AlertDateTime';
                    return null;
                });
            } else {
                changes = ReminderSaveChanges.All;
            }
            const apiRequest: CrmCustomerReminderSaveRequest = {
                id: reminder.value.id,
                active: reminder.value.active,
                alertDateTime: alertDateTimeString,
                name: reminder.value.name,
                onlyForMe: reminder.value.onlyForMe,
                crmCustomerId: props.crmCustomerId,
                changes: changes
            };
            const response = await api.saveCrmCustomerReminder(apiRequest);
            if (response.validationErrors?.length) {
                setValidator(response.validationErrors);
                swal.fire({
                    icon: 'error',
                    text: 'Invalid Form'
                });
                return;
            }
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            emit('saveReminder', response.data);
            swal.close();
        };

        async function deleteCrmCustomerReminder() {
            if (!reminder.value.id) {
                return;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('delete.alert'),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('businessClient.deleteContact.delete'),
                cancelButtonText: t('businessClient.deleteContact.undelete')
            });
            if (!swalResult.isConfirmed) {
                return;
            }
            await q.delay(200);
            swal.showLoading();
            const response = await api.deleteCrmCustomerReminder(reminder.value.id);
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return;
            }
            emit('deleteReminder', reminder.value.id);
            swal.close();
        }

        function updateAlertTime(val?: string) {
            if (val) {
                reminder.value.alertTime = val;
            }
        }
        function updateAlertDate(event: Date) {
            reminder.value.alertDate = moment(event).format(DateConstants.DATE_FORMAT_SHOW);
        }
        async function updateAlertDateTime() {
            if (!reminder.value.alertTime || !alertDate.value) {
                return;
            }
            loading.value = true;
            const alertDateString = moment(alertDate.value).format(DateConstants.DATE_FORMAT_SHOW);
            const alertDateTime = `${alertDateString} ${reminder.value.alertTime}`;
            const response = await api.getRelativeInterval(alertDateTime);
            if (response.errorMessage || !response.data) {
                return;
            }
            reminder.value.relativeInterval = response.data.relativeInterval;
            loading.value = false;
        }

        return {
            isOpen,
            reminder,
            originReminder,
            dateConstants,
            alertDate,
            invalidAlertDate,
            loading,
            open,
            close,
            save,
            submitForm,
            deleteCrmCustomerReminder,
            updateAlertTime,
            updateAlertDateTime,
            updateAlertDate
        };
    }
});
