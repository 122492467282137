
import { defineComponent, ref, onMounted, computed } from 'vue';
import {
    CrmCustomerDetailsModel,
    CrmCustomerEditorModel,
    CrmCustomerReminderDetailsModel,
    CrmCustomerMessage,
    CrmCustomerTimingChatMessage
} from '@/models/CrmCustomers';
import crmCustomerEditorModal from '@/components/CrmCustomers/CrmCustomerEditorModal.vue';
import crmCustomerActionsPopup from '@/components/CrmCustomers/CrmCustomerActionsPopup.vue';
import crmCustomerRemarksPopup from '@/components/CrmCustomers/CrmCustomerRemarksPopup.vue';
import CrmCustomerPageHeader from '@/components/CrmCustomers/CrmCustomerPageHeader.vue';
import crmCustomerReminderEditorModal from '@/components/CrmCustomers/CrmCustomerReminderEditorModal.vue';
import TimingChatMessagePopup from '@/components/CrmCustomers/TimingChatMessagePopup.vue';
import { api } from '@/services/Api';
import swal from 'sweetalert2';
import q from 'q';
import { SaveCrmCustomerReminderResponse, SaveTimingMessageResponse } from '@/models/Interfaces';
import { CrmCustomerHistoryTypes, CrmCustomerMessageType } from '@/models/Enums';
import { generalStore } from '@/store';
import router from '@/router';
import useOrder from '@/modules/useOrder';
import { OrderDetails, Customer } from '@/models/Order';
import { useI18n } from 'vue-i18n';
import { onBeforeRouteLeave } from 'vue-router';
import moment from 'moment';
import QuickReplyPopup from '@/components/CrmCustomers/QuickReplyPopup.vue';
import CrmCustomerChatMessagePopup from '@/components/CrmCustomers/CrmCustomerChatMessagePopup.vue';

export default defineComponent({
    name: 'CustomerDetails',
    props: {
        id: {
            type: String,
            required: true
        }
    },
    components: {
        crmCustomerEditorModal,
        crmCustomerActionsPopup,
        CrmCustomerPageHeader,
        crmCustomerRemarksPopup,
        crmCustomerReminderEditorModal,
        QuickReplyPopup,
        TimingChatMessagePopup,
        CrmCustomerChatMessagePopup
    },
    async setup(props) {
        let chatTimerId = 0;
        onBeforeRouteLeave(async () => {
            clearTimeout(chatTimerId);
            return true;
        });
        const messageAttachments = ref<File[]>([]);
        const reminderFilter = ref(1);
        const { t } = useI18n();
        const { saveStoreOrder } = useOrder();
        const crmCustomerMessageType = CrmCustomerMessageType;
        const message = ref('');
        const msgSent = ref(false);
        const messagedMarked = ref(false);
        const localCurrencySymbol = generalStore.state.localCurrencySymbol;
        const modalcrmCustomerEditor = ref<InstanceType<typeof crmCustomerEditorModal>>();
        const modalCrmCustomerChatMessage = ref<InstanceType<typeof CrmCustomerChatMessagePopup>>();
        const modalcrmCustomerActions = ref<InstanceType<typeof crmCustomerActionsPopup>>();
        const modalcrmCustomerReminderEditor = ref<InstanceType<typeof crmCustomerReminderEditorModal>>();
        const modalTimingChatMessage = ref<InstanceType<typeof TimingChatMessagePopup>>();
        const modalQuickReply = ref<InstanceType<typeof QuickReplyPopup>>();
        const crmCustomer = ref(new CrmCustomerDetailsModel());
        const remindersToShow = computed(() => {
            if (reminderFilter.value == 2) {
                return crmCustomer.value.reminders.filter(x => x.active);
            }
            return crmCustomer.value.reminders;
        });
        const crmCustomerHistoryTypes = CrmCustomerHistoryTypes;
        const fakeHistories = [
            { id: 1, type: crmCustomerHistoryTypes.Sms, date: '24/04/2024 12:30' },
            { id: 2, type: crmCustomerHistoryTypes.WhatsApp, date: '24/04/2024 12:30' },
            { id: 3, type: crmCustomerHistoryTypes.Email, date: '24/04/2024 12:30' },
            { id: 4, type: crmCustomerHistoryTypes.Lead, date: '24/04/2024 12:30' },
            { id: 5, type: crmCustomerHistoryTypes.Orders, date: '24/04/2024 12:30' },
            { id: 6, type: crmCustomerHistoryTypes.Quote, date: '24/04/2024 12:30' }
        ];
        //
        const historyExist = ref(false);
        //
        const loaded = ref(false);
        const selectedTab = ref(1);
        const filterType = ref<string>('ALL');
        function openEditcrmCustomerModal() {
            const toEdit: CrmCustomerEditorModel = {
                id: crmCustomer.value.id,
                firstName: crmCustomer.value.firstName,
                lastName: crmCustomer.value.lastName,
                email: crmCustomer.value.email,
                mobile: crmCustomer.value.mobile,
                birthdate: crmCustomer.value.birthdate,
                businessClientId: crmCustomer.value.businessClientId,
                businessClientName: crmCustomer.value.businessClientName
            };
            modalcrmCustomerEditor.value?.open(toEdit);
        }
        const modalcrmCustomerRemarks = ref<InstanceType<typeof crmCustomerRemarksPopup>>();
        function opencrmCustomerActionsModal() {
            modalcrmCustomerActions.value?.open();
        }
        function opencrmCustomerReminderEditorModal() {
            modalcrmCustomerActions.value?.open();
        }
        function openCrmCustomerChatMessageModal() {
            modalCrmCustomerChatMessage.value?.open(props.id, crmCustomer.value.fullName);
        }
        function updatecrmCustomerDetails(crmCustomerReq: CrmCustomerEditorModel) {
            crmCustomer.value.firstName = crmCustomerReq.firstName;
            crmCustomer.value.lastName = crmCustomerReq.lastName;
            crmCustomer.value.fullName = crmCustomerReq.lastName + ' ' + crmCustomerReq.firstName;
            crmCustomer.value.email = crmCustomerReq.email;
            crmCustomer.value.mobile = crmCustomerReq.mobile;
            crmCustomer.value.birthdate = crmCustomerReq.birthdate;
            crmCustomer.value.businessClientId = crmCustomerReq.businessClientId;
            crmCustomer.value.businessClientName = crmCustomerReq.businessClientName;
        }
        function getChatMessageClass(msg: CrmCustomerMessage) {
            let msgClass = '';
            if (msg.type == CrmCustomerMessageType.SYSTEM) {
                msgClass = 'crm-customer-chat-message-from-system';
            } else if (msg.type == CrmCustomerMessageType.CUSTOMER) {
                msgClass = 'crm-customer-chat-message-from-customer';
            } else if (msg.type == CrmCustomerMessageType.USER) {
                if (msg.isMe) {
                    msgClass = 'crm-customer-chat-message-from-me';
                } else {
                    msgClass = 'crm-customer-chat-message-from-user';
                }
            }
            return msgClass;
        }
        function getIconForTest(type: CrmCustomerHistoryTypes) {
            switch (type) {
                case crmCustomerHistoryTypes.Sms:
                    return 'icon-phone fs-24';
                case crmCustomerHistoryTypes.Email:
                    return 'icon-mail2 fs-24';
                case crmCustomerHistoryTypes.WhatsApp:
                    return 'icon-whatsapp fs-24';
                case crmCustomerHistoryTypes.Lead:
                    return 'icon-lead fs-18';
                case crmCustomerHistoryTypes.Quote:
                    return 'icon-quote fs-24';
                case crmCustomerHistoryTypes.Orders:
                    return 'icon-order fs-24';
                default:
                    return '';
            }
        }
        function opencrmCustomerRemarksModal() {
            modalcrmCustomerRemarks.value?.open(crmCustomer.value.remarks);
        }
        function updateRemarks(newRemarks: string) {
            crmCustomer.value.remarks = newRemarks;
        }
        function editReminder(reminder: CrmCustomerReminderDetailsModel) {
            modalcrmCustomerReminderEditor.value?.open(reminder);
        }
        function openAddRemiderPopup() {
            modalcrmCustomerReminderEditor.value?.open();
        }
        async function onSelectAction(actionType: string) {
            if (actionType == 'reminder') {
                openAddRemiderPopup();
            }
            if (actionType == 'neworder') {
                const order = new OrderDetails();
                order.businessClientId = crmCustomer.value.businessClientId;
                order.crmCustomerId = props.id;
                const customer = new Customer();
                customer.firstName = crmCustomer.value.firstName;
                customer.lastName = crmCustomer.value.lastName;
                customer.mobile = crmCustomer.value.mobile;
                customer.email = crmCustomer.value.email;
                order.leadCustomer = customer;
                saveStoreOrder(order);
                router.push({ name: 'neworder' });
            }
            if (actionType == 'deleteCustomer') {
                const swalResult = await swal.fire({
                    icon: 'info',
                    title: 'Are you sure you want to delete this?',
                    customClass: {
                        confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                        denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                    },
                    buttonsStyling: false,
                    showDenyButton: true,
                    showCloseButton: true,
                    confirmButtonText: 'Delete Customer',
                    denyButtonText: t('button.cancel')
                });
                if (!swalResult.isConfirmed) return;
                await q.delay(200);
                swal.showLoading();
                const response = await api.deleteCrmCustomer(props.id);
                if (response?.errorMessage || !response?.data) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage || 'error'
                    });
                    return;
                }
                router.push({ name: 'crm-customers' });
                swal.close();
            }
        }
        function saveReminder(reminder: SaveCrmCustomerReminderResponse) {
            const existReminder = crmCustomer.value.reminders.find(x => x.id == reminder.id);
            if (existReminder) {
                existReminder.id = reminder.id;
                existReminder.name = reminder.name;
                existReminder.active = reminder.active;
                existReminder.alertDateTime = reminder.alertDateTime;
                existReminder.onlyForMe = reminder.onlyForMe;
                existReminder.relativeInterval = reminder.relativeInterval;
            } else {
                const toSave: CrmCustomerReminderDetailsModel = {
                    id: reminder.id,
                    name: reminder.name,
                    active: reminder.active,
                    alertDateTime: reminder.alertDateTime,
                    onlyForMe: reminder.onlyForMe,
                    relativeInterval: reminder.relativeInterval
                };
                crmCustomer.value.reminders.push(toSave);
            }
            modalcrmCustomerReminderEditor.value?.close();
        }
        function deleteReminder(id: string) {
            const existReminderIndex = crmCustomer.value.reminders.findIndex(x => x.id == id);
            crmCustomer.value.reminders.splice(existReminderIndex, 1);
            modalcrmCustomerReminderEditor.value?.close();
        }
        async function deleteTimingChatMessage(id: string) {
            if (!id) {
                return false;
            }
            const swalResult = await swal.fire({
                icon: 'info',
                title: t('delete.alert'),
                customClass: {
                    confirmButton: 'stopsale-button-wrapper popup-bookit-button my-1 px-5',
                    cancelButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showCancelButton: true,
                confirmButtonText: t('businessClient.deleteContact.delete'),
                cancelButtonText: t('businessClient.deleteContact.undelete')
            });
            if (!swalResult.isConfirmed) {
                return false;
            }
            await q.delay(200);
            swal.showLoading();
            const response = await api.deleteTimingChatMessage(id);
            if (response.errorMessage || !response.data) {
                swal.fire({
                    icon: 'error',
                    text: response.errorMessage
                });
                return false;
            }
            const indexToDelete = crmCustomer.value.timingChatMessage.findIndex(x => x.id == id);
            crmCustomer.value.timingChatMessage.splice(indexToDelete, 1);
            swal.close();
            return true;
        }
        async function deleteTimingChatMessage2(id: string) {
            const isOk = await deleteTimingChatMessage(id);
            if (isOk) {
                modalTimingChatMessage.value?.close();
            }
        }

        let getNextMessages: (showOld?: boolean) => void = () => {
            'funtion template for stupid composition api';
        };
        async function getMessages(showOld = false, fromId?: string, showLoading = false) {
            if (showLoading) swal.showLoading();
            const chatResult = await api.GetCrmCustomerMessages(props.id, filterType.value, fromId, showOld);
            if (chatResult.data) {
                messagedMarked.value = false;
                crmCustomer.value.timingChatMessage = chatResult.data.timingChatMessages;
                if (!fromId) {
                    crmCustomer.value.messages = chatResult.data.messages;
                } else if (showOld) {
                    crmCustomer.value.messages.push(...chatResult.data.messages);
                } else {
                    crmCustomer.value.messages.unshift(...chatResult.data.messages);
                }
            }
            if (showLoading) swal.close();

            if (!showOld) {
                clearTimeout(chatTimerId);
                chatTimerId = setTimeout(getNextMessages, 30 * 1000);
            }
        }

        getNextMessages = async (showOld = false) => {
            let fromId: string | undefined = undefined;

            if (crmCustomer.value.messages.length > 0)
                fromId = showOld ? crmCustomer.value.messages[crmCustomer.value.messages.length - 1].id : crmCustomer.value.messages[0].id;
            await getMessages(showOld, fromId);
        };
        async function sendMsg(quickReplyId: string | null = null) {
            msgSent.value = true;
            if (!message.value.trim() && !messageAttachments.value.length && !quickReplyId) {
                msgSent.value = false;
                return;
            }
            if (msgSent.value) {
                swal.showLoading();
                const apiResult = await api.SendCrmCustomerMessage(props.id, message.value, messageAttachments.value, quickReplyId);
                if (apiResult.validationErrors?.length) {
                    const html = apiResult.validationErrors
                        .map(x => {
                            return `<div>${x.key}: ${x.msg}</div>`;
                        })
                        .join(',');
                    swal.fire({
                        icon: 'error',
                        html: html
                    });
                    return;
                }
                if (apiResult.errorMessage || !apiResult.data) {
                    swal.fire({
                        icon: 'error',
                        text: apiResult.errorMessage || 'No data'
                    });
                    return;
                }
                message.value = '';
                messageAttachments.value = [];
                await getNextMessages();
                msgSent.value = false;
                if (quickReplyId) {
                    modalQuickReply.value?.close();
                } else {
                    const textarea = document.getElementById('textMessage');
                    if (textarea) {
                        textarea.style.height = 'initial';
                    }
                }

                swal.close();
            }
        }
        function changeChatType(type: string) {
            filterType.value = type;
            getMessages(false, undefined, true);
        }
        function formatChatDate(date: string) {
            const d = moment(date, 'DD/MM/YYYY HH:mm');
            return moment().isSame(d, 'day') ? d.format('HH:mm') : d.format('DD/MM/YYYY HH:mm');
        }
        async function markMessagesAsRead() {
            if (crmCustomer.value.messages && crmCustomer.value.messages.length > 0 && messagedMarked.value == false) {
                const lastMessage = crmCustomer.value.messages[0];
                const response = await api.markCrmCustomerMessageAsRead(props.id, lastMessage.id);
                if (response.error || response.errorMessage) {
                    swal.fire({
                        icon: 'error',
                        text: response.errorMessage
                    });
                    return;
                }
                messagedMarked.value = true;
            }
        }
        function openSelectQuickReply() {
            modalQuickReply.value?.open();
        }
        function openTimingChatMessagePopup(timingChatMessage: CrmCustomerTimingChatMessage | null = null) {
            modalTimingChatMessage.value?.open(timingChatMessage);
        }
        function saveTimingChatMessage(data: SaveTimingMessageResponse) {
            const existTimingChatMessage = crmCustomer.value.timingChatMessage.find(x => x.id == data.id);
            if (existTimingChatMessage) {
                existTimingChatMessage.message = data.message;
                existTimingChatMessage.scheduleDateTime = data.scheduleDateTime;
            } else {
                const newTimingChatMessage: CrmCustomerTimingChatMessage = {
                    id: data.id,
                    message: data.message,
                    scheduleDateTime: data.scheduleDateTime,
                    relativeInterval: data.relativeInterval
                };
                crmCustomer.value.timingChatMessage.unshift(newTimingChatMessage);
            }
        }
        function callUploadingFunction(): void {
            document.getElementById('msg-attachment-input')?.click();
        }
        async function deleteMsgAttachment(attachmentIndex: number) {
            const swalResult = await swal.fire({
                icon: 'info',
                title: 'Are you sure you want to delete this attachment?',
                customClass: {
                    confirmButton: 'save-button-wrapper popup-bookit-button my-1 px-5',
                    denyButton: 'close-button-wrapper popup-bookit-button my-1 px-5'
                },
                buttonsStyling: false,
                showDenyButton: true,
                showCloseButton: true,
                confirmButtonText: 'Delete',
                denyButtonText: t('button.cancel')
            });
            if (!swalResult.isConfirmed) return;
            messageAttachments.value.splice(attachmentIndex, 1);
        }
        async function downloadAttachment(attachmentId: string, attachmentName: string) {
            console.log(attachmentId);
            swal.showLoading();
            const apiResponse = await api.downloadCrmCustomerMessageAttachment(attachmentId);
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return;
            }
            const blob = apiResponse.data;
            const url = window.URL.createObjectURL(blob);
            const a = document.createElement('a');
            a.href = url;
            a.download = attachmentName;
            document.body.appendChild(a);
            a.click();
            a.remove();
            window.URL.revokeObjectURL(url);
            swal.close();
        }
        const uploadingAttachmentValidation = (event: Event) => {
            const target = event.target as HTMLInputElement;
            const files = target.files;
            const maxSize = 5242880; // 5 MB
            if (!files) return;
            swal.showLoading();
            const validFiles: File[] = [];
            const fileTypes: string[] = ['doc', 'docx', 'xls', 'xlsx', 'txt', 'pdf'];
            for (const file of files) {
                const fileExt = file.name.split('.').pop();

                if (!fileTypes.includes(fileExt!)) {
                    swal.fire({
                        icon: 'error',
                        text: `Error: Unsupported file type for ${file.name}.`
                    });
                    return;
                }

                if (file.size > maxSize) {
                    swal.fire({
                        icon: 'error',
                        text: `File size for ${file.name} should be less than or equal to 5 MB.`
                    });
                    return;
                }

                validFiles.push(file);
            }

            if (target.files) {
                messageAttachments.value.push(...Array.from(validFiles));
            }
            swal.close();
        };
        const onMountedCall = async () => {
            swal.showLoading();
            const apiPromise = api.getCrmCustomer(props.id);
            await q.delay(400);
            const apiResponse = await apiPromise;
            if (apiResponse.errorMessage || !apiResponse.data) {
                swal.fire({
                    icon: 'error',
                    text: apiResponse.errorMessage || 'No data'
                });
                return;
            }
            crmCustomer.value = apiResponse.data.crmCustomer;
            getMessages();
            loaded.value = true;
            const readMsgCallback = (entries: any, observer: any) => {
                entries.forEach((entry: any) => {
                    markMessagesAsRead();
                });
            };

            const chatObserver = new IntersectionObserver(readMsgCallback, {
                threshold: [0.5] // If 50% of the element is in the screen, we count it!
                // Can change the thresholds based on your needs. The default is 0 - it'll run only when the element first comes into view
            });
            const chatContainer = document.getElementById('crm-customer-chat-div');
            if (chatContainer) {
                chatObserver.observe(chatContainer);
            }
            // historyExist.value = fakeHistories.length > 0;
            swal.close();
        };
        onMounted(onMountedCall);
        return {
            crmCustomer,
            loaded,
            selectedTab,
            historyExist,
            filterType,
            modalcrmCustomerEditor,
            modalcrmCustomerActions,
            modalcrmCustomerRemarks,
            modalcrmCustomerReminderEditor,
            crmCustomerHistoryTypes,
            fakeHistories,
            localCurrencySymbol,
            message,
            openEditcrmCustomerModal,
            opencrmCustomerActionsModal,
            opencrmCustomerRemarksModal,
            opencrmCustomerReminderEditorModal,
            changeChatType,
            updatecrmCustomerDetails,
            updateRemarks,
            getIconForTest,
            onSelectAction,
            saveReminder,
            deleteReminder,
            editReminder,
            sendMsg,
            formatChatDate,
            getChatMessageClass,
            crmCustomerMessageType,
            openAddRemiderPopup,
            reminderFilter,
            remindersToShow,
            modalQuickReply,
            openSelectQuickReply,
            openTimingChatMessagePopup,
            modalTimingChatMessage,
            saveTimingChatMessage,
            deleteTimingChatMessage,
            deleteTimingChatMessage2,
            callUploadingFunction,
            uploadingAttachmentValidation,
            deleteMsgAttachment,
            downloadAttachment,
            messageAttachments,
            getNextMessages,
            modalCrmCustomerChatMessage,
            openCrmCustomerChatMessageModal
        };
    }
});
